const configFilePath = import.meta.env.CONFIG_FILE_PATH || "config.json";

export interface Config {
  QRCODE_HSM_API_URL: string;
  SEAL_BASE_URL: string;
  BLOCKCHAIN_CACHE_SERVICE_URL: string;
  APP_SOURCE_ADDON_URL: string;
  CHROME_STORE_EXTENSION_URL: string;
  AETERNITY: {
    NODES_URLS: string[];
    COMPILERS_URLS: string[];
    CONTRACT_BYTECODE: string;
    TX_EXPLORER_TEMPLATE: string;
    BLOCK_EXPLORER_TEMPLATE: string;
  };
  CDN: {
    BASE_URL: string;
    STATUSES_BUCKET: string;
    KEYS_BUCKET: string;
    TAILS_BUCKET: string;
  };
  PRIVACY_POLICY_URL: string;
  IPFS_API_URL: string;
}

let config: Config;

// const removeBackSlashesFromUrls = <T>(config: T) => {
//   Object.keys(config).forEach((key) => {
//     const configEntryKey = key as keyof T;
//     const entry = config[configEntryKey];
//     if (entry && typeof entry === "object") {
//       removeBackSlashesFromUrls(entry);
//     } else if (
//       typeof entry === "string" &&
//       entry.startsWith("http") &&
//       entry.endsWith("/")
//     ) {
//       config[configEntryKey] = entry.slice(
//         0,
//         entry.length - 1
//       ) as unknown as T[keyof T];
//     }
//   });
// };

export const loadConfig = async () => {
  const loadedConfig = await fetch(configFilePath).then((response) =>
    response.json()
  );
  config = { ...config, ...loadedConfig };
  // removeBackSlashesFromUrls<Config>(config);
};

export default () => {
  return config;
};
